@import "./colors";

$fontSize-small: 0.66rem;
$fontSize-medium: 0.8rem;
$fontSize-normal: 1rem;
$fontSize-large: 1.5rem;
$fontSize-h3: 2em;

@mixin h3 {
  font-size: $fontSize-large;
  color: $primary;
  margin: 0;
  margin-bottom: 0.5em;
}

@mixin subtitle {
  font-size: $fontSize-normal;
  color: $primary;
}

@mixin small_secondary {
  font-size: $fontSize-small;
  color: $text-secondary;
}

@mixin small_darkSecondary {
  font-size: $fontSize-small;
  color: $text-darkSecondary;
}

@mixin medium_secondary {
  font-size: $fontSize-medium;
  color: $text-secondary;
}
