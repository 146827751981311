@import "../../Theme/colors";
@import "../../Theme/typography";

.chip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__key {
    font-size: $fontSize-small;
    font-weight: bold;
  }

  &__value {
  }
}

.filterbar {
  display: flex;
  position: relative;
  min-height: 2em;
  align-items: flex-end;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid $dividers-mediumGrey;

  &__tags {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    margin-right: 0.25em;
    margin-top: 0.25em;
  }

  &__button {
    flex: 0;
    flex-shrink: 0;
    font-size: $fontSize-medium;
    color: $primary;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
  }

  &__popover {
    padding: 1em 2em;
    width: 300px;
    display: flex;
    flex-direction: column;
  }

  &__popover-title {
    @include subtitle;
    margin: 0;
    margin-bottom: 0.5em;
  }

  &__input {
    min-width: 100px;
    width: 100%;
    flex: 1;
    margin-bottom: 1em !important;
  }

  &__input-container {
  }

  &__submit {
    align-self: flex-end;
  }
}
