@import "../../../Theme/colors";
@import "../../../Theme/typography";

.new-task {
  position: sticky;
  top: 6em;
  text-align: left;
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include h3;
  }

  &__action-container {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: 48%;
    }
  }

  &__form-control {
    margin-bottom: 1em !important;
  }

  &__select-options {
    list-style: none;
    padding-top: 2em;
    padding-left: 0;
  }

  &__select-option-field {
    margin-bottom: 1em;
    display: flex;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    .MuiFormControlLabel-label {
      font-size: 1em;
    }
  }

  &__requiredAction-text {
  }

  &__submit {
    align-self: flex-end;
  }

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }

  &__spinner {
  }
}
