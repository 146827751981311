.tasks {
  margin-top: 6em;

  &__list {
    position: relative;
  }

  &__list-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    background-color: rgba($color: #fff, $alpha: 0.5);
    display: flex;
    justify-content: center;
  }
}
