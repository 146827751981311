.login {
  background-image: url("../Assets/login-background.jpg");
  background-position: center;
  background-size: cover;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
  }

  &__logo {
    object-fit: contain;
    margin-bottom: 1em;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input-container {
    width: 100%;
    margin-bottom: 4em;
  }

  &__input {
    width: 100%;
  }

  &__button {
    width: 100%;
  }
}
