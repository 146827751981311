@import "../../../Theme/typography";
@import "../../../Theme/colors";

.task-detail {
  text-align: left;
  position: sticky;
  top: 6em;

  &__section {
    padding: 1em 2em;
  }

  &__title {
    @include h3;
  }

  &__section-title {
    @include subtitle;
    margin: 0;
  }

  &__label {
    @include small_darkSecondary;
    margin: 0;
    margin-bottom: 0.25em;
  }

  &__value {
    @include medium_secondary;
    margin: 0;
    margin-bottom: 1em;
    white-space: pre-line;
  }

  &__customers > * {
    padding-left: 0;
    padding-right: 0;
    background-color: white;
    border-bottom: 1px solid $dividers-lightGrey;
    cursor: default;
  }

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
