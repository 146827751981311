@import "../../Theme/typography";
@import "../../Theme/colors";

.task-item {
  &__container {
    display: flex;
    background-color: $background-lightGrey;
    text-align: left;
    padding: 1em 2em;
    border-bottom: 1px solid $dividers-mediumGrey;
    cursor: pointer;

    &--selected {
      background-color: $background-white;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: $primary;
      }
    }
  }

  &__left-pane {
    flex-basis: 15%;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &__completion-badge {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid $error;

    &--completed {
      background-color: $success;
      border: none;

      > * {
        font-size: 0.8em;
      }
    }
  }

  &__main {
    flex: 1;
    text-align: left;
  }

  &__title {
  }

  &__shortDesc {
    @include small_darkSecondary;
  }

  &__desc {
    @include small_darkSecondary;
  }

  &__action-container > button {
    margin-right: 1em;
  }

  &__aside {
    flex-basis: 20%;
    flex-shrink: 1;
    flex-grow: 0;
    text-align: right;
  }

  &__label {
    @include small_darkSecondary;
    margin: 0;
    margin-bottom: 0.25em;
  }

  &__value {
    @include medium_secondary;
    margin: 0;
    margin-bottom: 0.5em;
  }
}
