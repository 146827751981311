$transparent: rgba(0, 0, 0, 0);
$background-darkGrey: #d9d9d9;
$background-grey: #f8f8f8;
$background-lightGrey: #f8f8f8;
$background-white: #ffffff;

$text-main: #241f20;
$text-secondary: #949599;
$text-darkSecondary: #6d6d6e;
$text-white: #ffffff;
$text-black: #000000;

$dividers-lightGrey: #d9d9d9;
$dividers-mediumGrey: #dbdbdb;

$primary: #00a5e2;
$success: #d0e202;
$error: #c3024d;
$warn: #eabf00;
