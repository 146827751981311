.aquisition-form {
  text-align: left;
  display: flex;
  flex-direction: column;

  &__form-control {
    margin-bottom: 1em !important;
  }

  &__submit {
    align-self: flex-end;
  }
}
