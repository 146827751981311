@import "../../Theme/colors";

.input-field {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1em;

  &__label {
    color: white;
    font-size: 0.65em;
    margin-bottom: 0.5em;
  }

  &__input {
    padding: 0.65em 1em;
    border: 2px solid $primary;
    box-sizing: border-box;
    display: block;
    width: 100%;
    box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.3);
  }
}
