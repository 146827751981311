.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
