@import "../../Theme/typography";
@import "../../Theme/colors";

.list-item {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25em;
  }

  &__customer-number {
    @include small_darkSecondary;
  }

  &__last-visit {
    @include small_secondary;
  }

  &__container {
    background-color: $background-lightGrey;
    text-align: left;
    padding: 1em 2em;
    cursor: pointer;
    box-sizing: border-box;

    &--selected {
      background-color: $background-white;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $primary;
      }
    }
  }

  &__address-container {
  }

  &__task-badge {
    left: 0 !important;
    right: auto !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  &__task-text {
    margin-left: 2rem;
    font-size: 0.66rem;
    text-transform: uppercase;
    color: $text-secondary;
  }

  &__two-col {
    display: flex;
    justify-content: space-between;
  }
}
